import React, { useState, useContext } from 'react';
import { ChatContext } from './ChatContext';
import SendIcon from '@mui/icons-material/Send';
import { Button, TextField } from '@mui/material';

const token = localStorage.getItem("datagramToken");

const MessageInput: React.FC = () => {
  const [input, setInput] = useState('');
  const { currentSession, ws,addUserMessage } = useContext(ChatContext);

  const handleSend = () => {
    console.log({input})
    if (input.trim()) {
      if (currentSession && ws) {
        // Send message to the WebSocket server
        ws.current.send({ session_id: currentSession.id, messages: input , "token": token});
        addUserMessage(input);
        setInput('');
      } else {
        console.error('No active session.');
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  }

  return (
    <div className="p-4 w-full bg-white border-t flex">
      <div className="flex items-center">
        <TextField
          fullWidth
          value={input}
          onKeyDown={handleKeyDown}
          onChange={(e) => setInput(e.target.value)}
          variant="outlined"
          placeholder="Ask AI anything..."
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "black",
              "& fieldset": {
                borderColor: "#4A5568",
              },
              "&:hover fieldset": {
                borderColor: "#A0AEC0",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#63B3ED",
              },
            },
          }}
        />

        <Button
          onClick={handleSend}
          variant="contained"
          color="primary"
          className="ml-2"
          endIcon={<SendIcon />}
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default MessageInput;
