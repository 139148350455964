import axios from 'axios';

// Define a function to get the token. Replace this with your actual token retrieval logic.
const getToken = () => {
  // Example: Replace this with your token retrieval logic (e.g., from localStorage or a context)
  return localStorage.getItem('authToken') || '';
};

// Create an Axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_CHAT_BOT_URL || 'https://a.datagram.ai'
});

// Add a request interceptor to include the token in every request
apiClient.interceptors.request.use(
  (config) => {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers['Access-Control-Allow-Origin'] = "*";
    console.log({config})
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;
