import React, { useState,useContext } from 'react';
import { Button, TextField, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { ChatContext } from './ChatContext';

const SessionSelector: React.FC = () => {
  const { sessions, setCurrentSession, startNewSession , currentSession} = useContext(ChatContext);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div
        className={`${
          sidebarOpen ? "w-1/4" : "w-0"
        } transition-all fixed lg:static lg:translate-x-0 text-gray-200 p-4 h-full`}
      >
      <div className="absolute top-4 right-4 z-10">
        <IconButton onClick={toggleSidebar} color="primary">
          {sidebarOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </div>
    <div className={`${ sidebarOpen ? "": "hidden"} space-y-4`}>
        <Button
          variant="contained"
          color="primary"
          className="mb-4 p-2 text-white rounded-lg"
          onClick={startNewSession}
        >
          Start New Session
        </Button>
          {sessions.map((session) => {

            const isActive = currentSession && session.id === currentSession.id;
            return           <div 
              key={session.id}
              onClick={() => setCurrentSession(session)}
              className={`text-sm	text-gray-600 rounded cursor-pointer hover:bg-gray-200 p-2 cursor-pointer ${isActive ? 'bg-gray-200' : ''}`}
            >
              {session.name}
            </div>
          }
          )}
      </div>
    </div>
  );
};

export default SessionSelector;
