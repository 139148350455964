import React from 'react';
import { useScript } from "@uidotdev/usehooks";
import { ChatProvider } from '../../components/ChatBot/ChatContext';
import ChatContainer from '../../components/ChatBot//ChatContainer';
import MessageInput from '../../components/ChatBot//MessageInput';
import SessionSelector from '../../components/ChatBot/SessionSelector';

const ChatPage: React.FC = () => {


  const status = useScript(
    `https://www.gstatic.com/charts/loader.js`,
    {
      removeOnUnmount: false,
    }
  );
  return (
    <ChatProvider>
      <div className="flex h-screen">
          <SessionSelector />
        <div className="flex flex-col w-full">
          {status === 'ready' ?  <ChatContainer /> : <div>Loading...</div>}
          <MessageInput />
        </div>
      </div>
    </ChatProvider>
  );
};

export default ChatPage;
