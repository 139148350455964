import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux'


type Message = {
  sender: 'user' | 'bot';
  text: string;
};

const ChatBot: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>('');

  const profile = useSelector((store) => store.app.profile)

  console.log(profile)

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleSendMessage = () => {
    if (input.trim()) {
      const newMessages = [...messages, { sender: 'user', text: input }];
      setMessages(newMessages);
      setInput('');
      // Simulate a bot response
      setTimeout(() => {
        const botMessage: Message = {
          sender: 'bot',
          text: `You said: ${input}`, // Simulating bot response
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      }, 1000); // Simulating delay
    }
  };

  return (
    <div className="h-screen flex justify-center items-center bg-gray-800">
      <Button
        variant="contained" color="primary"
        className="fixed bottom-5 right-5 text-white font-bold py-2 px-4 rounded-full"
        onClick={toggleChat}
      >
        {isOpen ? 'Close Chat' : 'Open Chat'}
      </Button>

      {isOpen && (
        <div className="fixed bottom-20 right-5 w-30 bg-gray-900 text-white shadow-lg rounded-lg p-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Hi {profile?.user_name }!</h2>
            <Button onClick={toggleChat}>
              <CloseIcon />
            </Button>

          </div>
          <p className="text-sm text-gray-400">How can I help you today?</p>
          {messages.length === 0 ? (
            <>
              <div className="text-center text-gray-400 mt-4">
                <p>Start a conversation...</p>
              </div>
              <div className="my-4">
                <p className="text-sm text-gray-500">Suggested</p>
                <ul>
                  <li className="text-blue-400 cursor-pointer"
                    onClick={() => {
                      setInput('Get last month campagin benchmark');
                      handleSendMessage();
                    }}
                  >Get last month campagin benchmark</li>
                  <li className="text-blue-400 cursor-pointer"
                    onClick={() => {
                      setInput('Ask about Media Share of voice');
                      handleSendMessage();
                    }}
                  >Ask about Media Share of voice </li>
                  <li className="text-blue-400 cursor-pointer"
                    onClick={() => {
                      setInput('Find action items');
                      handleSendMessage();
                    }}
                   >Find action items</li>
                  <li
                    onClick={() => {
                      setInput('Translate');
                      handleSendMessage();
                    }}
                  className="text-blue-400 cursor-pointer">Translate</li>
                </ul>
              </div>
            </>
          )
            :
            (<div className="h-60 overflow-y-auto mb-4">
              {messages.map((message, index) => (
                <div key={index} className={`mb-2 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
                  <span
                    className={`inline-block py-2 px-3 rounded-lg ${message.sender === 'user' ? 'bg-blue-500' : 'bg-gray-700'}`}
                  >
                    {message.text}
                  </span>
                </div>
              ))}
            </div>)
          }

          <div className="relative">
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              className="w-full bg-gray-700 text-white rounded-full px-4 py-2 focus:outline-none"
              placeholder="Ask anything..."
              onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <Button className="absolute right-2 top-2 text-gray-400" onClick={handleSendMessage}>
              <SubdirectoryArrowLeftIcon />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatBot;

