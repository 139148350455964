import React, { createContext, useState, useEffect, ReactNode, useRef } from 'react';
import { getSessions, createChatSession, getSessionMessages } from './api';
import WebSocketClient from './websocket';
import { Message, Session } from './types';

const CHAT_BOT_URL = process.env.REACT_APP_CHAT_BOT_URL || 'https://a.datagram.ai';
const CHAT_BOT_DOMAIN = (new URL(CHAT_BOT_URL)).hostname;

interface ChatContextType {
  sessions: Session[];
  currentSession: Session | null;
  messages: Message[];
  startNewSession: () => void;
  setCurrentSession: (session: Session) => void;
  ws: any;
  addUserMessage: (message: string) => void;
}

const defaultContext: ChatContextType = {
  sessions: [],
  currentSession: null,
  messages: [],
  startNewSession: () => {},
  setCurrentSession: () => {},
  ws: null,
  addUserMessage: () => {}
};

export const ChatContext = createContext<ChatContextType>(defaultContext);

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [currentSession, setCurrentSession] = useState<Session | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);


  const ws = useRef<WebSocketClient | null>(null)

  const addUserMessage = (message: string) => {
    const newMessage = {
      id: Date.now().toString(),
      session_id: currentSession?.id,
      sender: "human",
      text: message,
    } as Message;
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  }

  useEffect(() => {
    // Load sessions on mount
    const fetchSessions = async () => {
      const data = await getSessions();
      setSessions(data);
      if (data.length > 0) {
        setCurrentSession(data[0]);
      }
    };
    fetchSessions();
  }, []);

  useEffect(() => {
    if (currentSession) {
      // Fetch messages when current session changes
      const fetchMessages = async () => {
        try {
          const data = await getSessionMessages(currentSession.id);
          console.log({data})
          setMessages(data);
        }
        catch (error) {
          console.error('Error fetching messages:', error);
          setMessages([]);
        }
      };
      fetchMessages();

      // Connect WebSocket and listen for messages
      const protocal = CHAT_BOT_URL.includes('https:') ? 'wss' : 'ws';
      const wsClient = new WebSocketClient(`${protocal}://${CHAT_BOT_DOMAIN}/ws/llm?session_id=` + currentSession?.id);
      wsClient.connect();
      wsClient.on("default", (message: Message) => {
        console.log('Received message:', message);
        const newMessage = {
          id: new Date().toISOString(),
          session_id: currentSession?.id,
          sender: "bot",
          text: message.output,
        } as Message;
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      });
      ws.current = wsClient
    }
  }, [currentSession]);

  useEffect(() => {
    console.log('Current session:', currentSession);
    if (!currentSession) {
      return;
    }
    // Connect WebSocket and listen for messages
    const protocal = CHAT_BOT_URL.includes('https:') ? 'wss' : 'ws';
    const wsClient = new WebSocketClient(`${protocal}://${CHAT_BOT_DOMAIN}/ws/llm?session_id=` + currentSession?.id);
    wsClient.connect();
    wsClient.on("default", (message: Message) => {
      console.log('Received message:', message);
      const newMessage = {
        id: new Date().toISOString(),
        session_id: currentSession?.id,
        sender: "bot",
        text: message.output,
      } as Message;
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
    ws.current = wsClient
  }, [currentSession]);

  const startNewSession = async () => {
    const newSession = await createChatSession();
    if (newSession) {
      setSessions((prevSessions) => [newSession, ...prevSessions,]);
      setCurrentSession(newSession);
    }
  };

  return (
    <ChatContext.Provider
      value={{
        sessions,
        currentSession,
        messages,
        startNewSession,
        setCurrentSession,
        ws,
        addUserMessage
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
