export const getRandomChar = () => {
    return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    }

// Get the time difference between two dates in a human-readable format
export const getTimeDifference = (previous: number) => {

    const current = new Date();

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    const elapsed = current.getTime() - previous;
    const yearsCount = Math.round(elapsed/msPerYear );

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';   
    }
    else if (!isNaN(yearsCount)) {
        return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
    }
    return 'just now';
}
export const containsMarkdown : (text:string) => boolean = (text) => {
    // Define some common Markdown patterns
    const markdownPatterns = [
      /\*\*(.*?)\*\*/g,        // Bold (**bold**)
      /\*(.*?)\*/g,            // Italic (*italic*)
      /__(.*?)__/g,            // Bold (__bold__)
      /_(.*?)_/g,              // Italic (_italic_)
      /\[.*?\]\(.*?\)/g,       // Links [text](url)
      /\#\s.*$/gm,             // Headings # Heading
      /\`\`\`[\s\S]*?\`\`\`/g, // Code blocks ```code```
      /\`.*?\`/g,              // Inline code `code`
      /\-\s.*$/gm,             // Lists - item
      /\>\s.*$/gm,             // Blockquotes > quote
      /\!\[.*?\]\(.*?\)/g      // Images ![alt](src)
    ];
  
    // Check if any of the patterns match the text
    return markdownPatterns.some((pattern) => pattern.test(text));
  }
  