import React, { useContext } from 'react';
import { ChatContext } from './ChatContext';
import { useSelector } from "react-redux";
import ChatBubble from './ChatBubble';

const ChatContainer: React.FC = () => {
  const { messages, currentSession } = useContext(ChatContext);
  const profile = useSelector((store) => store.app.profile);

  const scrollToBottom = () => {
    const chatContainer = document.querySelector('.flex-1');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }

  React.useEffect(() => {
    scrollToBottom();
  }, [messages, currentSession]);

  console.log({messages})
  return (
    <div className="flex-1 overflow-y-auto p-4 bg-gray-100">
      {messages ? messages.map((message) => (
        <ChatBubble key={message.id} message={message} profile={profile} />
      )): null}
    </div>
  );
};

export default ChatContainer;
